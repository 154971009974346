import { UntitledIcon } from '@faceup/icons'
import { ucUpsell } from '@faceup/ui'
import { useUpsellContext } from './contexts'

type UpsellIconProps = {
  isColorFromParent?: boolean
  size?: number
}

export const UpsellIcon = (props: UpsellIconProps) => {
  const { isColorFromParent, size } = props
  const { upsell } = useUpsellContext()

  if (upsell?.showUpsell === false) {
    return null
  }

  return (
    <UntitledIcon
      icon={ucUpsell}
      color={isColorFromParent ? undefined : 'var(--ant-orange-6)'}
      size={size}
    />
  )
}
