import { ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { UntitledIcon, type UntitledIconData } from '@faceup/icons'
import { ulAlertCircle } from '@faceup/icons/ulAlertCircle'
import { ulCheckCircle } from '@faceup/icons/ulCheckCircle'
import { ulInfoCircle } from '@faceup/icons/ulInfoCircle'
import { ulXCircle } from '@faceup/icons/ulXCircle'
import { ulXClose } from '@faceup/icons/ulXClose'
import { App, type ModalFuncProps } from 'antd'

const sharedStyles: ModalFuncProps['styles'] = {
  content: { padding: '20px 24px', borderRadius: '12px' },
  mask: {
    backgroundColor: '#062D46B2',
  },
}

const sharedProps: ModalFuncProps = {
  styles: sharedStyles,
  closable: true,
  centered: true,
  okCancel: true,
  closeIcon: <UntitledIcon icon={ulXClose} size={22} color='#062D46' />,
}

const icons: Record<
  Partial<NonNullable<ModalFuncProps['type']>>,
  { icon: UntitledIconData; color: string }
> = {
  info: { icon: ulInfoCircle, color: '#0085FF' },
  success: { icon: ulCheckCircle, color: '#32C27B' },
  error: { icon: ulXCircle, color: '#F54070' },
  warning: { icon: ulAlertCircle, color: '#FA914B' },
  confirm: { icon: ulAlertCircle, color: '#FA914B' },
  warn: { icon: ulAlertCircle, color: '#FA914B' },
}

export const useModal = () => {
  const app = App.useApp()

  const customInfo = (props: ModalFuncProps) => {
    return app.modal.info({
      icon: <InfoCircleOutlined />,
      ...sharedProps,
      ...props,
    })
  }

  const customConfirm = (props: ModalFuncProps) => {
    const icon = props.type ? (
      <UntitledIcon icon={icons[props.type].icon} color={icons[props.type].color} />
    ) : undefined
    return app.modal.confirm({
      icon,
      ...sharedProps,
      ...props,
    })
  }

  const customWarning = (props: ModalFuncProps) => {
    return app.modal.warning({
      icon: <ExclamationCircleOutlined />,
      ...sharedProps,
      ...props,
    })
  }

  return {
    ...app.modal,
    info: customInfo,
    warning: customWarning,
    confirm: customConfirm,
  }
}
