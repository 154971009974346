import { UntitledIcon } from '@faceup/icons'
import { ulXClose } from '@faceup/icons/ulXClose'
import { Modal as ANTDModal, Flex } from '@faceup/ui-base'
import type { JSX, ReactNode } from 'react'
import { Segment, type SegmentProps } from '../CoreComponents/Segment'

type ModalComponent = (props: ModalProps) => JSX.Element
type ModalSegmentComponent = (props: ModalSegmentProps) => JSX.Element
export type ModalSubcomponents = { Segment: ModalSegmentComponent }
type ComplexComponent = ModalComponent & ModalSubcomponents

type ModalWidth = 520 | 620 | 644 | 800 | 950

export type ModalVariants = 'primary' | 'danger'

export type ModalProps = {
  children: ReactNode
  opened: boolean
  onClose?: () => void
  title?: ReactNode
  width?: ModalWidth
  footer?: ReactNode
  variant?: ModalVariants
  'data-cy'?: string
}

export const Modal: ComplexComponent = (_props: ModalProps) => {
  const {
    children,
    opened,
    onClose,
    width,
    title,
    variant = 'default',
    footer,
    'data-cy': dataCy,
    ...props
  } = _props
  return (
    <ANTDModal
      title={title}
      open={opened}
      onCancel={onClose ? onClose : () => null}
      footer={footer ?? null}
      centered
      width={width}
      bodyProps={{ 'data-cy': dataCy }}
      {...props}
      closeIcon={
        onClose && (
          <Flex justify='flex-end'>
            <UntitledIcon icon={ulXClose} color='#547599' size={24} />
          </Flex>
        )
      }
    >
      {children}
    </ANTDModal>
  )
}

type ModalSegmentProps = Omit<SegmentProps, 'paddingContent' | 'paddingHeader' | 'marginDivider'>

export const ModalSegment: ModalSegmentComponent = (props: ModalSegmentProps) => (
  <Segment {...props} marginDivider='24px 0' />
)

Modal.Segment = ModalSegment
