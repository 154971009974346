import { UntitledIcon } from '@faceup/icons'
import { ulHelpCircle } from '@faceup/icons/ulHelpCircle'
import { Tooltip, type TooltipProps } from '../Tooltip'

export type MoreInfoProps = Pick<TooltipProps, 'title' | 'placement'>

export const MoreInfo = (props: MoreInfoProps) => (
  <Tooltip {...props}>
    <UntitledIcon icon={ulHelpCircle} color='#062D46' />
  </Tooltip>
)
