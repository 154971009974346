import { gql, useMutation } from '@apollo/client'
import { useUser } from '@faceup/member'
import { useInViewport } from '@faceup/ui'
import { useIdle } from '@mantine/hooks'
import { useEffect } from 'react'
import type {
  MarkAsReadMutation,
  MarkAsReadMutationVariables,
  UseMarkMessagesAsReadFragments_comment,
} from '../__generated__/globalTypes'

export const UseMarkMessagesAsReadFragments = {
  UseMarkMessagesAsReadFragments_comment: gql`
    fragment UseMarkMessagesAsReadFragments_comment on CompanyReportFollowUpComment {
      id
      readByMemberIds
      isMessageReadByVictim
      author {
          ... on CompanyVictim {
              id
          }

          ... on Member {
              id
          }
      }
      report {
        id
        company {
          id
          mother {
            id
          }
        }
      }
    }
  `,
}

const markAsReadMutation = gql`
  mutation MarkAsReadMutation($input: markAsReadCompanyFollowUpCommentsInput!) {
    markAsReadCompanyFollowUpComments(input: $input) {
      success
    }
  }
`

type UseMarkMessagesAsRead = (params: {
  message: UseMarkMessagesAsReadFragments_comment
  onCompleted?: () => void
}) => {
  ref: ReturnType<typeof useInViewport>['ref']
}

export const useMarkMessagesAsRead: UseMarkMessagesAsRead = ({ message, onCompleted }) => {
  const idle = useIdle(2000)
  const { inViewport: isInViewport, ref } = useInViewport({
    threshold: 1,
  })
  const user = useUser()

  const [markAsRead] = useMutation<MarkAsReadMutation, MarkAsReadMutationVariables>(
    markAsReadMutation,
    {
      onError: error => console.error(error),
      onCompleted,
      refetchQueries: ['AdminLayoutPartnerQuery'],
    }
  )

  useEffect(() => {
    const userId = user.viewer?.id
    const shouldMessageBeMarkedAsRead =
      message.author?.id !== userId &&
      // in akutan, filter messages which are not already read by myself
      ((!message.readByMemberIds?.some(m => m === userId) && user.application === 'akutan') ||
        // in followup, filter messages, which are not already read by myself
        (!message.isMessageReadByVictim && user.application === 'follow-up'))
    // mark message as read when the user is active on the page
    if (shouldMessageBeMarkedAsRead && !idle && isInViewport) {
      void markAsRead({
        variables: {
          input: {
            commentId: message.id,
            // will mark all the report's messages as read
            reportId: message.report?.id ?? '',
            motherId: message.report?.company?.mother?.id ?? message.report?.company?.id ?? '',
          },
        },
      })
    }
  }, [markAsRead, message, idle, isInViewport, user])

  return { ref }
}
