import { Flex } from '@faceup/ui-base'
import type { ReactNode } from 'react'

type FormItemsWrapperProps = {
  children: ReactNode
}

export const FormItemsWrapper = ({ children }: FormItemsWrapperProps) => (
  <Flex vertical gap={16}>
    {children}
  </Flex>
)
