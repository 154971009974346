import { gql, useQuery } from '@apollo/client'
import { Link, useParams } from '@faceup/router'
import { Card, ContentLayout, PageContentSkeleton } from '@faceup/ui'
import { Descriptions, Divider, Space, notification } from '@faceup/ui-base'
import { FormItemType } from '@faceup/utils'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import CloseReportButton from '../Components/CloseReportButton'
import NonRegisteredEmails from '../Components/NonRegisteredEmails'
import RouteNotFound from '../RouteNotFound'
import type { ReportDetailQuery, ReportDetailQueryVariables } from '../__generated__/globalTypes'
import { DATE_FORMAT } from '../constants'

const query = {
  ReportDetailQuery: gql`
    query ReportDetailQuery($reportId: CompanyReportGlobalId!) {
      report(reportId: $reportId) {
        id
        emailsSent
        answers {
          id
          values
          formItem {
            id
            type
          }
        }
        closed
        createdAt
        company {
          id
          organizationalUnitName
          country
          billing {
            id
            plan
          }

          isVerified
          isPendingVerification
        }
      }
      psychologistReport(reportId: $reportId) {
        id
        victimName
        moreInfo
      }
    }
  `,
}

const ReportDetail = () => {
  const { id } = useParams<'id'>()
  const [victimName, setVictimName] = useState('')
  const [moreInfo, setMoreInfo] = useState('')

  const { loading, data } = useQuery<ReportDetailQuery, ReportDetailQueryVariables>(
    query.ReportDetailQuery,
    {
      skip: !id,
      variables: { reportId: id ?? '' },
      onError: error => {
        console.error(error)
        notification.error({
          message: 'GQL ERROR',
          description: error.message,
        })
      },
    }
  )

  useEffect(() => {
    const decryptReport = async () => {
      const report = data?.psychologistReport
      if (!report) {
        return
      }
      setVictimName(report.victimName ?? '')
      setMoreInfo(report.moreInfo ?? '')
    }

    decryptReport()
  }, [data?.psychologistReport])

  if (loading && !data) {
    return <PageContentSkeleton />
  }

  const report = data?.report

  if (!report) {
    return <RouteNotFound />
  }

  const classRoomValue = report?.answers?.find(
    answer => answer.formItem.type === FormItemType.Classroom
  )?.values[0]

  return (
    <ContentLayout header={<ContentLayout.Header title='Report' />}>
      <Space direction='vertical' size='large'>
        <Card title='Basic Info'>
          <Descriptions>
            <Descriptions.Item label='Name'>{victimName}</Descriptions.Item>
            <Descriptions.Item label='Info'>{moreInfo}</Descriptions.Item>
            <Descriptions.Item label='Class room'>{classRoomValue}</Descriptions.Item>
            <Descriptions.Item label='Created'>
              {report?.createdAt && moment(report?.createdAt).format(DATE_FORMAT)}
            </Descriptions.Item>
            <Descriptions.Item label='E-mails sent'>
              {report?.emailsSent ? 'Yes' : 'No'}
            </Descriptions.Item>
          </Descriptions>
        </Card>
        <Card title='Institution'>
          <Space>
            <Descriptions>
              <Descriptions.Item label='Institution name'>
                {report?.company?.organizationalUnitName}
              </Descriptions.Item>
              <Descriptions.Item label='Institution'>
                <Link to={routes => routes.institution({ id: report.company.id })}>
                  {report.company.organizationalUnitName}
                </Link>
              </Descriptions.Item>
            </Descriptions>
          </Space>
        </Card>
        <Card title='Actions'>
          <Space direction='vertical' style={{ width: '100%', flexWrap: 'wrap' }}>
            <CloseReportButton {...report} />
            <Divider />
            <NonRegisteredEmails {...report} />
          </Space>
        </Card>
      </Space>
    </ContentLayout>
  )
}

export default ReportDetail
