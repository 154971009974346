import { gql, useQuery } from '@apollo/client'
import { getPersonalKeys, saveSSOKeys } from '@faceup/crypto'
import { useMotherId } from '@faceup/institution'
import { sharedMessages, useIntl } from '@faceup/localization'
import { notification } from '@faceup/ui-base'
import type { Language } from '@faceup/utils'
import { type ReactNode, createContext, useContext } from 'react'
import type { UserQuery } from '../__generated__/globalTypes'
import { useAuth } from '../hooks/auth'

const query = {
  UserQuery: gql`
    query UserQuery {
      memberViewer {
        id
        legacyId
        isPartner
        language
        sso
        name
        email
        phone
        createdAt
        profileImageUrl
        partner {
          id
          verificationStatus
          institutions {
            id
          }
        }
        keys {
          id
          privateKey
          publicKey
          partnerPermissions {
            id
            type
            enabled
            motherId
          }
        }
        hasAccessToMultipleInstitutions
        motherImplicit {
          id
          organizationalUnitName
          type
          country
          config {
            id
            institutionName
          }
        }
        partnerCompanyIds
      }
    }
  `,
}

export type UserQuery_memberViewer = NonNullable<UserQuery['memberViewer']>

export type ApplicationType = 'akutan' | 'kredenc' | 'follow-up'

export type UserContextProps = {
  logout: () => void
  data: {
    id: string
  }
  // TODO: Simplify and abstractize viewer
  viewer?: UserQuery_memberViewer | null
} & (
  | {
      // It shouldn't be here, but we need it for now (otherwise we should create some App package and move it there)
      application: Extract<ApplicationType, 'akutan' | 'kredenc'> | null
    }
  | {
      application: Extract<ApplicationType, 'follow-up'>
    }
)

export const UserContext = createContext<UserContextProps>({
  logout: () => undefined,
  viewer: null,
  application: null,
  data: {
    id: '',
  },
})

type UserProviderProps = {
  onLogout: () => void
  onChangeLanguage: (language: Language) => void
  children: ReactNode
} & (
  | {
      application: Extract<ApplicationType, 'akutan' | 'kredenc'>
      userId?: never
    }
  | {
      // for now because follow-up is not using UserProvider globally
      application: Extract<ApplicationType, 'follow-up'>
      userId: string
    }
)

/**
 * When app is loaded we first need to know little info about user, mainly if s/he has access to
 * multiple institutions (indirectly via MemberType.motherImplicit). If motherImplicit exists we can
 * set motherId in MotherContext right away and continue with other queries, if not user needs to
 * choose via Institutions.tsx.
 */
export const UserProvider = ({
  children,
  onLogout,
  onChangeLanguage,
  application,
  userId,
}: UserProviderProps) => {
  const { formatMessage } = useIntl()
  const { setMotherId, getMotherIdWithNull } = useMotherId()
  const auth = useAuth()

  const { client, data } = useQuery<UserQuery>(query.UserQuery, {
    // @todo: not sure about this
    // fetchPolicy: 'cache-first',
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
    onCompleted: async data => {
      // User has invalid JWT
      const viewer = data?.memberViewer
      if (!viewer?.id) {
        logout()
        return
      }
      if (viewer.language) {
        onChangeLanguage(viewer.language)
      }
      const { privateKey } = await getPersonalKeys()
      if (!privateKey && viewer.sso && viewer.keys?.privateKey) {
        await saveSSOKeys({
          privateKey: viewer.keys.privateKey ?? '',
          publicKey: viewer.keys?.publicKey ?? '',
          rememberMe: false,
        })
      }

      /*
       * Non-partner users take mother ID from deprecated motherImplicit resolver: they currently have
       * access only to one institution, so we can assume they actually have exactly one mother
       * accessible to them.
       * Partner users have to choose the mother from the UI (Institutions.tsx).
       *
       * We need to refactor the UI to support multiple institutions to everyone.
       */
      if (!getMotherIdWithNull() && data.memberViewer?.motherImplicit?.id) {
        setMotherId(data.memberViewer?.motherImplicit?.id)
      }
    },
    skip: !auth.isAuthenticated() || application === 'kredenc',
  })

  const logout = async () => {
    client.stop()
    await client.clearStore()
    onLogout()
    auth.logout()
    // no need to clean MotherContext because it will be cleaned once we reload the page
    window.location.href = '/'
  }

  // for now because follow-up is not using UserProvider globally
  if (application === 'follow-up') {
    return (
      <UserContext.Provider
        value={{
          logout: () => null,
          application,
          viewer: null,
          data: {
            id: userId,
          },
        }}
      >
        {children}
      </UserContext.Provider>
    )
  }

  return (
    <UserContext.Provider
      value={{
        application,
        logout,
        viewer: data?.memberViewer,
        data: {
          id: data?.memberViewer?.id ?? '',
        },
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => useContext(UserContext)
