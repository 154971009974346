import { gql, useMutation, useQuery } from '@apollo/client'
import { UntitledIcon } from '@faceup/icons'
import { ulKey01 } from '@faceup/icons/ulKey01'
import { ulLock01 } from '@faceup/icons/ulLock01'
import {
  CreateMemberModal,
  CreateMemberModalFragments,
  EditMemberModal,
  EditMemberModalFragments,
  MotherProvider,
} from '@faceup/institution'
import { useSearchParams } from '@faceup/router'
import { Button, Card, TableEnhanced, Tooltip, UserAvatar } from '@faceup/ui'
import {
  Divider,
  Pagination,
  Space,
  Tag,
  Typography,
  notification,
  useMessage,
  useModal,
} from '@faceup/ui-base'
import { AccountType } from '@faceup/utils'
import { Badge, Group, Stack } from '@mantine/core'
import moment from 'moment-timezone'
import { Fragment, useEffect, useState } from 'react'
import { useAdminAccessRights } from '../Contexts/AdminAccessRightsProvider'
import {
  type CompanyMembersQuery,
  type CompanyMembersQueryVariables,
  type DeleteMemberMutation,
  type DeleteMemberMutationVariables,
  type EditMemberModal_memberToEdit,
  Institution,
  InvitationStatus,
  type RemovePartnerUserMutation,
  type RemovePartnerUserMutationVariables,
  type ResendMemberInvitationMutation,
  type ResendMemberInvitationMutationVariables,
  type TransferOwnershipModal_member,
} from '../__generated__/globalTypes'
import { DATE_FORMAT, DATE_TIME_FORMAT } from '../constants'
import GrantAccessActionModal, {
  fragments as GrantAccessModalFragments,
} from './GrantAccessActionModal'
import MoreOptions from './MoreOptions'
import PermissionText, { fragments as PermissionTextFragments } from './PermissionText'
import { TransferOwnershipFragments, TransferOwnershipModal } from './TransferOwnershipModal'

type CompanyMembersQuery_allCompanyByIdMembers_items_item = NonNullable<
  NonNullable<CompanyMembersQuery['allCompanyByIdMembers']>['items'][number]
>

const { Text } = Typography

const query = {
  CompanyMembersQuery: gql`
    query CompanyMembersQuery(
      $institutionId: CompanyGlobalId!
      $motherId: CompanyGlobalId!
      $page: Int!
      $rowsPerPage: Int!
    ) {
      institution(institutionId: $institutionId) {
        id
        organizationalUnitName
        config {
          id
          reportCategories {
            id
          }
        }
        descendents {
          id
          organizationalUnitName
        }

        ...CreateMemberModal_institution
        ...EditMemberModal_institution
      }
      allCompanyByIdMembers(companyId: $motherId, page: $page, rowsPerPage: $rowsPerPage) {
        totalCount
        items {
          id
          name
          email
          phone
          profileImageUrl
          companyIds(motherId: $motherId)
          editable(motherId: $motherId)
          accountType(motherId: $motherId)
          invitation
          sso
          isPartner
          lastLogin
          mother(motherId: $motherId) {
            id
            config {
              id
              reportCategories {
                id
              }
            }
            descendents {
              id
            }
          }
          companies(motherId: $motherId) {
            id
            organizationalUnitName
          }
          keys {
            id
            twoFactorAuthentication
            permissions(motherId: $motherId) {
              id
              enabled
              ...PermissionText_permission
            }
          }
          ...TransferOwnershipModal_member
          ...EditMemberModal_memberToEdit
          ...GrantAccessActionModal_user
        }
      }
    }

    ${TransferOwnershipFragments.TransferOwnershipModal_member}
    ${EditMemberModalFragments.EditMemberModal_memberToEdit}
    ${PermissionTextFragments.PermissionText_permission}
    ${GrantAccessModalFragments.GrantAccessActionModal_user}
    ${EditMemberModalFragments.EditMemberModal_institution}
    ${CreateMemberModalFragments.CreateMemberModal_institution}
  `,
}

const mutations = {
  DeleteMember: gql`
    mutation DeleteMemberMutation($input: DeleteMemberInput!) {
      deleteMember(input: $input) {
        success
        deletedMemberId
      }
    }
  `,
  RemovePartnerUser: gql`
    mutation RemovePartnerUserMutation($input: RemovePartnerUserInput!) {
      removePartnerUser(input: $input) {
        success
        deletedMemberId
      }
    }
  `,
  ResendMemberInvitation: gql`
    mutation ResendMemberInvitationMutation($input: ResendMemberInvitationInput!) {
      resendMemberInvitation(input: $input) {
        member {
          id
          invitation
        }
      }
    }
  `,
}

type Props = {
  institutionId: string
}

const MembersTable = ({ institutionId }: Props) => {
  const { isAllowedInstitutionEdit, isAllowedInstitutionMemberActions } = useAdminAccessRights()
  const pageSize = 10

  const [params] = useSearchParams()

  const [page, setPage] = useState(1)
  const [grantAccessVisible, setGrantAccessVisible] = useState(false)
  const [memberToEdit, setMemberToEdit] = useState<EditMemberModal_memberToEdit | null>(null)
  const [memberToGrantOwnerShip, setMemberToGrantOwnerShip] =
    useState<TransferOwnershipModal_member | null>(null)
  const [createMemberVisible, setCreateMemberVisible] = useState<boolean>(false)
  const message = useMessage()
  const modal = useModal()

  const { data, loading, refetch } = useQuery<CompanyMembersQuery, CompanyMembersQueryVariables>(
    query.CompanyMembersQuery,
    {
      onError: error => {
        console.error(error)
        notification.error({
          message: 'GQL Error',
          description: error.message,
        })
      },
      fetchPolicy: 'cache-and-network',
      variables: {
        institutionId: institutionId ?? '',
        motherId: institutionId,
        page: page - 1,
        rowsPerPage: pageSize,
      },
    }
  )

  const [resendInvitation] = useMutation<
    ResendMemberInvitationMutation,
    ResendMemberInvitationMutationVariables
  >(mutations.ResendMemberInvitation, {
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL Error',
        description: error.message,
      })
    },
    onCompleted: () => {
      message.success('Resent')
    },
  })

  const [deleteMember] = useMutation<DeleteMemberMutation, DeleteMemberMutationVariables>(
    mutations.DeleteMember,
    {
      onError: error => {
        console.error(error)
        notification.error({
          message: 'GQL Error',
          description: error.message,
        })
      },
      onCompleted: data => {
        if (!data.deleteMember?.success) {
          message.error('Unable to delete member')
        }
      },
    }
  )

  const [removePartnerUser] = useMutation<
    RemovePartnerUserMutation,
    RemovePartnerUserMutationVariables
  >(mutations.RemovePartnerUser, {
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL Error',
        description: error.message,
      })
    },
    onCompleted: data => {
      if (!data.removePartnerUser?.success) {
        message.error('Unable to remove partner user')
      }
    },
  })

  const makeTableRow = (item: CompanyMembersQuery_allCompanyByIdMembers_items_item) => ({
    __typename: 'Member' as const,
    id: item.id,
    name: item.name ?? '',
    companyIds: item.companyIds,
    phone: item.phone ?? '',
    companies: item.companies,
    email: item.email ?? '',
    editable: item.editable,
    profileImageUrl: item.profileImageUrl ?? null,
    permissions: item.keys?.permissions ?? [],
    invitation: item.invitation,
    sso: Boolean(item.sso),
    lastLogin: item.lastLogin,
    mother: item.mother ?? null,
    accountType: item.accountType ?? null,
    security: {
      sso: Boolean(item.sso),
      twoFactorAuthentication: Boolean(item.keys?.twoFactorAuthentication),
    },
  })

  const institution = data?.institution
  const members = data?.allCompanyByIdMembers?.items ?? []
  const allCompaniesCount = ((institution?.descendents ?? []).length ?? 0) + 1
  const companies = [institution, ...(institution?.descendents ?? [])]
  const totalCount = data?.allCompanyByIdMembers?.totalCount || 0
  const allCategoriesCount = (institution?.config?.reportCategories?.length ?? 0) + 1

  useEffect(() => {
    const action = params.get('action')
    if (action === 'grantAccess') {
      setGrantAccessVisible(true)
    }
  }, [params])

  return (
    <MotherProvider motherId={institutionId}>
      <Card
        title='Members'
        hasNoPadding
        extra={
          <Button disabled={!isAllowedInstitutionEdit} onClick={() => setCreateMemberVisible(true)}>
            Add member
          </Button>
        }
      >
        <TableEnhanced<ReturnType<typeof makeTableRow>>
          style={{ marginTop: 20 }}
          loading={loading}
          rowKey='id'
          dataSource={members.map(member => makeTableRow(member))}
          locale={{ emptyText: ' ' }}
          scroll={{ x: 'max-content' }}
          columns={[
            {
              title: 'Name',
              dataIndex: 'name',
              key: 'name',
              width: allCompaniesCount > 1 ? '25%' : '35%',
              render: (name, member) => (
                <Space align='center'>
                  <UserAvatar user={member} style={{ marginRight: 10 }} />
                  <Space direction='vertical' size={0}>
                    <Space>
                      <Text style={{ fontSize: 14 }}>{name || member.email}</Text>
                      {member.accountType === AccountType.Owner && (
                        <Badge
                          sx={theme => ({
                            color: theme.primaryColor,
                            backgroundColor: theme.colors.primary[0],
                          })}
                        >
                          Owner
                        </Badge>
                      )}
                    </Space>
                    <Text type='secondary' style={{ fontSize: 13 }}>
                      {member.email} {member.phone && <>({member.phone})</>}
                    </Text>
                  </Space>
                </Space>
              ),
            },
            ...(allCompaniesCount > 1
              ? [
                  {
                    title: 'Institution',
                    dataIndex: 'companies',
                    key: 'companies',
                    filters: companies?.map(company => ({
                      text: company?.organizationalUnitName ?? '',
                      value: company?.id ?? '',
                    })),
                    // @ts-expect-error companies and member cannot inherit types
                    onFilter: (value, member) => member.companyIds.includes(value),
                    width: '20%',
                    // @ts-expect-error companies and member cannot inherit types
                    render: (companies, member) => {
                      if (member.companyIds.length === allCompaniesCount && allCompaniesCount > 1) {
                        return <Tag color='default'>All</Tag>
                      }

                      return (
                        <Space align='center'>
                          {companies.slice(0, 1).map((company: { id: string; name: string }) => (
                            <Tag color='default' key={company?.id ?? ''}>
                              {company?.name}
                            </Tag>
                          ))}
                          {companies.length > 1 && (
                            <Tag color='default'>+{companies.length - 1}</Tag>
                          )}
                        </Space>
                      )
                    },
                  },
                ]
              : []),
            {
              title: 'Permissions',
              dataIndex: 'permissions',
              key: 'permissions',
              width: allCompaniesCount ? '32%' : '42%',
              render: (_, member) => (
                <Text>
                  {member.permissions
                    .filter(permission => permission?.enabled)
                    .sort((a, b) => (a?.type?.charCodeAt(0) ?? 0) - (b?.type?.charCodeAt(0) ?? 0))
                    .map(permission => (
                      <Fragment key={permission?.id ?? ''}>
                        <PermissionText
                          permission={permission}
                          key={permission?.id ?? ''}
                          allCategoriesCount={allCategoriesCount}
                        />
                        <Divider type='vertical' />
                      </Fragment>
                    ))}
                </Text>
              ),
            },
            {
              title: 'Security',
              dataIndex: 'security',
              width: '8%',
              key: 'security',
              render: security => (
                <Stack>
                  {security.sso && (
                    <Group>
                      <UntitledIcon icon={ulKey01} />
                      SSO
                    </Group>
                  )}
                  {security.twoFactorAuthentication && (
                    <Group>
                      <UntitledIcon icon={ulLock01} />
                      2FA
                    </Group>
                  )}
                  {!security.sso && !security.twoFactorAuthentication && (
                    <Text type='secondary'>-</Text>
                  )}
                </Stack>
              ),
            },
            {
              title: 'Status',
              dataIndex: 'invitation',
              key: 'invitation',
              width: '8%',
              render: (invitation, { sso }) => (
                <>
                  {invitation === InvitationStatus.Active && <Tag color='#217F50'>Active</Tag>}
                  {invitation === InvitationStatus.Expired && <Tag color='#B60024'>Expired</Tag>}
                  {invitation === InvitationStatus.Sent && <Tag color='#E66B00'>Sent</Tag>}
                  {sso && <Tag color='gold'>SSO</Tag>}
                </>
              ),
            },
            {
              title: 'Last login',
              dataIndex: 'lastLogin',
              key: 'lastLogin',
              width: '10%',
              render: lastLogin => (
                <Tooltip title={`${moment(lastLogin).format(DATE_TIME_FORMAT)}`}>
                  <span>{lastLogin ? moment(lastLogin).format(DATE_FORMAT) : ''}</span>
                </Tooltip>
              ),
            },
            ...(isAllowedInstitutionMemberActions
              ? [
                  {
                    title: '',
                    dataIndex: 'id',
                    key: 'id',
                    width: '5%',
                    render: (id: string) => {
                      const member = members?.find(m => m?.id === id) ?? null
                      if (!member?.editable) {
                        return null
                      }
                      return (
                        <MoreOptions
                          menu={{
                            items: [
                              ...(member.invitation === InvitationStatus.Active
                                ? [
                                    {
                                      key: 'edit',
                                      label: 'Edit',
                                      onClick: () => setMemberToEdit(member),
                                    },
                                    ...(member.accountType !== AccountType.Owner
                                      ? [
                                          {
                                            key: 'transfer-ownership',
                                            label: 'Transfer ownership',
                                            onClick: () => setMemberToGrantOwnerShip(member),
                                          },
                                        ]
                                      : []),
                                  ]
                                : [
                                    {
                                      key: 'resend',
                                      label: 'Resend',
                                      onClick: () =>
                                        resendInvitation({
                                          variables: {
                                            input: {
                                              memberId: member.id,
                                              motherId: institutionId,
                                            },
                                          },
                                        }),
                                    },
                                  ]),
                              {
                                key: 'delete',
                                label: 'Delete',
                                onClick: () => {
                                  if (totalCount === 1) {
                                    modal.error({
                                      title: 'Delete last member',
                                      okText: 'Ok',
                                    })
                                    return
                                  }

                                  if (member.isPartner) {
                                    modal.confirm({
                                      title: 'Remove partner user from this institution',
                                      okText: 'Ok',
                                      onOk: async () => {
                                        await removePartnerUser({
                                          variables: {
                                            input: {
                                              motherId: institutionId,
                                              memberId: member.id,
                                            },
                                          },
                                        })
                                        refetch()
                                      },
                                      cancelText: 'Cancel',
                                    })
                                  } else {
                                    modal.confirm({
                                      title: 'Delete user',
                                      okText: 'Ok',
                                      onOk: async () => {
                                        await deleteMember({
                                          variables: {
                                            input: {
                                              motherId: institutionId,
                                              memberId: member.id,
                                            },
                                          },
                                        })
                                        refetch()
                                      },
                                      cancelText: 'Cancel',
                                    })
                                  }
                                },
                              },
                            ],
                          }}
                        />
                      )
                    },
                  },
                ]
              : []),
          ]}
          pagination={false}
        />

        {memberToEdit && institution && (
          <EditMemberModal
            institution={institution}
            member={null}
            memberToEdit={memberToEdit}
            opened={Boolean(memberToEdit)}
            onClose={() => setMemberToEdit(null)}
            onCompleted={refetch}
          />
        )}

        {createMemberVisible && institution && (
          <CreateMemberModal
            opened
            member={null}
            institution={institution}
            onCompleted={() => refetch()}
            onClose={() => setCreateMemberVisible(false)}
          />
        )}

        <GrantAccessActionModal
          visible={grantAccessVisible}
          close={() => setGrantAccessVisible(false)}
          users={members ?? []}
          institution={Institution.Company}
          institutionId={institutionId}
        />

        <TransferOwnershipModal
          member={memberToGrantOwnerShip}
          setMember={setMemberToGrantOwnerShip}
          institutionId={institutionId}
        />

        <Pagination
          style={styles.pagination}
          pageSize={pageSize}
          current={page}
          total={totalCount || 0}
          onChange={newPage => setPage(newPage)}
          showSizeChanger={false}
        />
      </Card>
    </MotherProvider>
  )
}

const styles = {
  pagination: { textAlign: 'right', marginTop: 20 },
} as const

export default MembersTable
