import { gql, useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { Link } from '@faceup/router'
import { ContentLayout, PageContentSkeleton } from '@faceup/ui'
import {
  Card,
  Col,
  List,
  Pagination,
  PlusOutlined,
  Row,
  Select,
  Space,
  Typography,
  notification,
} from '@faceup/ui-base'
import { DEFAULT_COUNTRY, convertCountryToCountryName } from '@faceup/utils'
import { useEffect, useState } from 'react'
// This page visual should be similiar with akutan
import {
  type AvailablePartnersQuery,
  type CompanyServicesQuery,
  type CompanyServicesQueryVariables,
  type CompanyServicesSupportedCountriesQuery,
  type Country,
  Institution,
} from '../../__generated__/globalTypes'
import CreateCompanyServiceModal from './CreateCompanyServiceModal'

const { Title, Text } = Typography

const query = {
  CompanyServicesQuery: gql`
    query CompanyServicesQuery(
      $page: Int!
      $rowsPerPage: Int!
      $country: Country
      $institutionType: Institution
    ) {
      companyServices(
        page: $page
        rowsPerPage: $rowsPerPage
        country: $country
        institutionType: $institutionType
      ) {
        totalCount
        items {
          id
          providerName
          description
          countries
          logoUrl
        }
      }
    }
  `,

  CompanyServicesSupportedCountriesQuery: gql`
    query CompanyServicesSupportedCountriesQuery {
      companyServicesSupportedCountries
    }
  `,
  AvailablePartnersQuery: gql`
    query AvailablePartnersQuery {
      partners(page: 0, rowsPerPage: 100000, verificationStatus: [Approved]) {
        totalCount
        items {
          id
          name
        }
      }
    }
  `,
}

const CompanyServices = () => {
  const [visibleModal, setVisibleModal] = useState(false)
  const [country, setCountry] = useState<Country | null>(null)
  const [institutionType, setInstitutionType] = useState<Institution | null>(null)
  const [page, setPage] = useState(1)
  const rowsPerPage = 15

  const countriesData = useQuery<CompanyServicesSupportedCountriesQuery>(
    query.CompanyServicesSupportedCountriesQuery,
    {
      onError: error => {
        console.error(error.message)
        notification.error({
          message: 'GQL Error',
          description: error.message,
        })
      },
    }
  )

  const { data: partnersData, loading } = useQuery<AvailablePartnersQuery>(
    query.AvailablePartnersQuery,
    {
      onError: error => {
        console.error(error)
        notification.error({
          message: 'GQL Error',
          description: error.message,
        })
      },
    }
  )

  const { data, refetch } = useQuery<CompanyServicesQuery, CompanyServicesQueryVariables>(
    query.CompanyServicesQuery,
    {
      onError: error => {
        console.error(error.message)
        notification.error({
          message: 'GQL Error',
          description: error.message,
        })
      },
      variables: {
        page: page - 1,
        rowsPerPage,
        country,
        institutionType,
      },
    }
  )

  useEffect(() => {
    refetch({
      page: page - 1,
      rowsPerPage,
      country,
    })
  }, [page, refetch, country])

  const companyServicesTotalCount = data?.companyServices?.totalCount
  const companyServices = [
    ...(page === 1 ? [{ id: null }] : []), // add button only on first page
    ...(data?.companyServices?.items?.map(item => ({
      id: item?.id,
      name: item?.providerName ?? '',
      description: item?.description,
      logoUrl: item?.logoUrl ?? '',
      countries: (item?.countries ?? []).map(countryShortcut =>
        convertCountryToCountryName(countryShortcut, 'en')
      ),
    })) ?? []),
  ]

  const supportedCountries = countriesData?.data?.companyServicesSupportedCountries ?? []

  if (loading && !partnersData) {
    return <PageContentSkeleton />
  }

  return (
    <ContentLayout header={<ContentLayout.Header title='Services' />}>
      <Space direction='vertical' size='large' style={{ width: '100%' }}>
        <Card>
          <Row gutter={16}>
            <Col xs={24} sm={8} md={6}>
              <InputLabel>Country</InputLabel>
              <Select
                style={{ width: '100%' }}
                allowClear
                placeholder='Country'
                onChange={(value: Country) => {
                  setCountry(value)
                  setPage(1)
                }}
                options={supportedCountries.map(country => ({
                  label: convertCountryToCountryName(country ?? DEFAULT_COUNTRY, 'en'),
                  value: country ?? '',
                }))}
              />
            </Col>
            <Col xs={24} sm={8} md={6}>
              <InputLabel>Institution</InputLabel>
              <Select
                style={{ width: '100%' }}
                allowClear
                placeholder='Institution'
                onChange={(value: Institution) => {
                  setInstitutionType(value)
                  setPage(1)
                }}
                options={Object.values(Institution).map(institution => ({
                  label: institution ?? '',
                  value: institution ?? '',
                }))}
              />
            </Col>
          </Row>
        </Card>

        <Card>
          <ServicesList
            grid={{
              gutter: 16,
              xs: 1,
              sm: 1,
              md: 2,
              lg: 2,
              xl: 3,
              xxl: 4,
            }}
            dataSource={companyServices}
            // biome-ignore lint/suspicious/noExplicitAny:
            renderItem={(item: any) => (
              <List.Item>
                {item.id ? (
                  <Link to={routes => routes.service({ id: item.id })}>
                    <ServiceCard>
                      <Space direction='vertical' size='middle'>
                        <ServiceLogo src={item.logoUrl} />
                        <Title level={4}>{item.name}</Title>
                      </Space>

                      <Space direction='vertical' size='large'>
                        <Text type='secondary' strong>
                          {item.countries.join(' | ')}
                        </Text>
                        <ElipsisText>{item.description}</ElipsisText>
                      </Space>
                    </ServiceCard>
                  </Link>
                ) : (
                  <AddService onClick={() => setVisibleModal(true)}>
                    <AddIcon />
                    Add service
                  </AddService>
                )}
              </List.Item>
            )}
          />

          <Pagination
            style={{ textAlign: 'right', marginTop: 20 }}
            pageSize={rowsPerPage}
            current={page}
            total={companyServicesTotalCount ?? 0}
            onChange={setPage}
            showSizeChanger={false}
          />

          <CreateCompanyServiceModal
            partners={partnersData?.partners?.items}
            visible={visibleModal}
            setVisible={setVisibleModal}
          />
        </Card>
      </Space>
    </ContentLayout>
  )
}

const InputLabel = styled.span`
  display: inline-block;
  margin: 10px 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #232323;
`

const ServicesList = styled(List)`
  .ant-col {
    height: 100%;
  }

  .ant-list-item {
    height: calc(100% - 16px);
  }
`

const ServiceCard = styled(Card)`
  height: 100%;

  .ant-card-body {
    display: flex;
    flex-direction: column;
    padding: 36px;
  }
`

const ServiceLogo = styled.div<{ src: string }>`
  height: 60px;
  width: 100%;
  background: url('${({ src }) => src}') center left no-repeat;
  background-size: contain;
`

const AddService = styled(ServiceCard)`
  cursor: pointer;
  border: 1px dashed #0e9af7;

  .ant-card-body {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 36px;
    height: 100%;
  }
`

const AddIcon = styled(PlusOutlined)`
  font-size: 50px;
  color: #818181;
  margin-bottom: 10px;
`

const ElipsisText = styled.div`
  max-width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
`

export default CompanyServices
