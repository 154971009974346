import { gql } from '@apollo/client'
import { Card, TableEnhanced, Text } from '@faceup/ui'
import {
  BCP47Language,
  ChannelType,
  convertCountryToCountryName,
  translateLanguageName,
} from '@faceup/utils'
import {
  HotlineOrderStatus,
  type ReportSourceHotlineTable_hotline as HotlineReportSourceType,
} from '../__generated__/globalTypes'
import ApproveHotlineModal from './ApproveHotlineModal'

export const ReportSourceHotlineTable_hotline = gql`
  fragment ReportSourceHotlineTable_hotline on ReportSource {
    id
    name
    sourceType
    order {
      id
      status
    }
    defaultLanguage
    languages
    isEnabled
    shortLink
    config {
      ... on AutomatedHotlineReportSourceConfiguration {
        id
        phoneNumber
        phoneNumberType
        automatedHotlineCountry: country
      }
      ... on LiveHotlineReportSourceConfiguration {
        id
        phoneNumber
        liveHotlineCountry: country
      }
    }
  }
`

type Props = {
  reportSources: HotlineReportSourceType[]
  title: string
  loading: boolean
  refetch: () => void
}

const ReportSourcesHotlineTable = ({ reportSources, title, loading, refetch }: Props) => {
  const makeTableRow = (reportSource: HotlineReportSourceType) => ({
    id: reportSource.id,
    name: reportSource.name,
    status: reportSource.order?.status,
    isEnabled: reportSource.isEnabled,
    sourceType: reportSource.sourceType,
    shortLink:
      reportSource?.config?.__typename === 'LiveHotlineReportSourceConfiguration'
        ? reportSource.shortLink
        : null,
    phoneNumber:
      reportSource?.config?.__typename === 'AutomatedHotlineReportSourceConfiguration' ||
      reportSource?.config?.__typename === 'LiveHotlineReportSourceConfiguration'
        ? reportSource.config.phoneNumber
        : null,
    phoneNumberType:
      reportSource?.config?.__typename === 'AutomatedHotlineReportSourceConfiguration'
        ? reportSource.config.phoneNumberType
        : null,
    country:
      reportSource?.config?.__typename === 'AutomatedHotlineReportSourceConfiguration'
        ? reportSource.config.automatedHotlineCountry
        : reportSource?.config?.__typename === 'LiveHotlineReportSourceConfiguration'
          ? reportSource.config.liveHotlineCountry
          : null,
    languages: reportSource.languages,
    defaultLanguage: reportSource.defaultLanguage,
  })

  return (
    <Card.Segment title={title} hasNoPadding={Boolean(reportSources.length)}>
      {reportSources.length ? (
        <TableEnhanced<ReturnType<typeof makeTableRow>>
          loading={loading}
          rowKey='id'
          dataSource={reportSources.map(reportSource => makeTableRow(reportSource))}
          locale={{ emptyText: ' ' }}
          scroll={{ x: 'max-content' }}
          columns={[
            {
              title: 'ID',
              dataIndex: 'id',
              key: 'id',
              render: id => atob(id).split(':')[1],
            },
            {
              title: 'Name',
              dataIndex: 'name',
              key: 'name',
            },
            {
              title: 'Type',
              dataIndex: 'phoneNumberType',
              key: 'phoneNumberType',
            },
            {
              title: 'Is enabled',
              dataIndex: 'isEnabled',
              key: 'isEnabled',
              render: isEnabled => <>{isEnabled ? 'Yes' : 'No'}</>,
            },
            {
              title: 'Order status',
              dataIndex: 'status',
              key: 'status',
            },
            {
              title: 'Phone number',
              dataIndex: 'phoneNumber',
              key: 'phoneNumber',
            },
            {
              title: 'Country',
              dataIndex: 'country',
              key: 'country',
              render: country => (country ? convertCountryToCountryName(country) : null),
            },
            {
              title: 'Languages',
              dataIndex: 'languages',
              key: 'languages',
              render: (languages: HotlineReportSourceType['languages'], row) => (
                <>
                  {languages?.map(language => (
                    <Text
                      key={language}
                      weight={language === row.defaultLanguage ? 'bold' : 'normal'}
                    >
                      {translateLanguageName(language, BCP47Language.en_US)}
                    </Text>
                  ))}
                </>
              ),
            },
            {
              title: 'Short link',
              dataIndex: 'shortLink',
              key: 'shortLink',
              render: shortLink =>
                shortLink ? (
                  <>
                    <a
                      target='_blank'
                      href={`https://www.faceup.com/c/${shortLink}`}
                      rel='noreferrer noopener'
                    >
                      https://faceup.com/c/{shortLink}
                    </a>
                    <br />
                    <a
                      target='_blank'
                      href={`https://www.nntb.cz/c/${shortLink}`}
                      rel='noreferrer noopener'
                    >
                      https://nntb.cz/c/{shortLink}
                    </a>
                  </>
                ) : null,
            },
            {
              title: 'Actions',
              key: 'approve',
              width: '10%',
              render: (_, row) =>
                row.status === HotlineOrderStatus.Processing &&
                (row.sourceType === ChannelType.AutomatedHotline ||
                  row.sourceType === ChannelType.LiveHotline) && (
                  <ApproveHotlineModal
                    type={row.sourceType}
                    reportSourceId={row.id}
                    refetch={refetch}
                  />
                ),
            },
          ]}
          pagination={false}
        />
      ) : (
        <Text>This institution does not have a {title} hotline yet.</Text>
      )}
    </Card.Segment>
  )
}

export default ReportSourcesHotlineTable
