import { gql, useQuery } from '@apollo/client'
import { Link, useParams } from '@faceup/router'
import { Card, ContentLayout, PageContentSkeleton } from '@faceup/ui'
import { Descriptions, Space, notification } from '@faceup/ui-base'
import { DEFAULT_COUNTRY, Plan, convertCountryToCountryName } from '@faceup/utils'
import moment from 'moment-timezone'
import ForceDeleteInstitutionButton from '../Components/ForceDeleteInstitution'
import LinkCompanyToPartner from '../Components/LinkCompanyToPartner'
import MembersTable from '../Components/MembersTable'
import PaymentLink from '../Components/PaymentLink'
import RegistrationButtons from '../Components/RegistrationButtons'
import ReportSourcesCard from '../Components/ReportSourcesCard'
import UnlinkCompanyFromPartner from '../Components/UnlinkCompanyFromPartner'
import { useAdminAccessRights } from '../Contexts/AdminAccessRightsProvider'
import RouteNotFound from '../RouteNotFound'
import {
  Institution,
  type InstitutionQuery,
  type InstitutionQueryVariables,
} from '../__generated__/globalTypes'
import { DATE_FORMAT } from '../constants'

export const query = {
  InstitutionQuery: gql`
    query InstitutionQuery($institutionId: CompanyGlobalId!, $motherId: CompanyGlobalId!) {
      institution(institutionId: $institutionId) {
        id
        type
        identificationNumber
        country
        registered
        pdfPassword(motherId: $motherId)
        
        config {
          id
          institutionName
          street
          city
          zipCode
          state
        }

        billing {
          id
          subscriptionStart
          subscriptionEnd
          subscriptionStatus
          invoicedTo
          plan
          customerUrl
        }

        partner {
          id
          name
        }

        realInstitution
        isVerified
        createdAt
      }
    }
  `,
}

const InstitutionDetail = () => {
  const {
    isAllowedInstitutionEdit,
    isAllowedInstitutionLink,
    isAllowedCreatePaymentUrl,
    isAllowedApproveOrRejectSchool,
  } = useAdminAccessRights()
  const { id } = useParams<'id'>()

  const { loading, data } = useQuery<InstitutionQuery, InstitutionQueryVariables>(
    query.InstitutionQuery,
    {
      fetchPolicy: 'cache-and-network',
      skip: !id,
      variables: { motherId: id ?? '', institutionId: id ?? '' },
      onError: error => {
        console.error(error)
        notification.error({
          message: 'GQL ERROR',
          description: error.message,
        })
      },
    }
  )

  if (loading && !data) {
    return <PageContentSkeleton />
  }

  const institution = data?.institution

  if (institution?.__typename !== 'Company') {
    return <RouteNotFound />
  }

  return (
    <ContentLayout header={<ContentLayout.Header title={institution.config.institutionName} />}>
      <Space direction='vertical' size='large' style={{ width: '100%' }}>
        <Card title='Basic info'>
          <Descriptions style={{ marginTop: 20 }}>
            <Descriptions.Item label='Type'>{institution?.type}</Descriptions.Item>
            <Descriptions.Item label='Identification number'>
              {institution?.identificationNumber}
            </Descriptions.Item>
            <Descriptions.Item label='Country'>
              {convertCountryToCountryName(institution?.country ?? DEFAULT_COUNTRY)}
            </Descriptions.Item>
            <Descriptions.Item label='Plan'>{institution?.billing?.plan}</Descriptions.Item>
            <Descriptions.Item label='Street'>{institution?.config.street}</Descriptions.Item>
            <Descriptions.Item label='City'>{institution?.config.city}</Descriptions.Item>
            <Descriptions.Item label='ZipCode'>{institution?.config.zipCode}</Descriptions.Item>
            <Descriptions.Item label='State'>{institution?.config.state}</Descriptions.Item>
            <Descriptions.Item label='Registered'>
              {institution?.createdAt && moment(institution?.createdAt).format(DATE_FORMAT)}
            </Descriptions.Item>
            <Descriptions.Item label='Subscription start'>
              {institution?.billing?.subscriptionStart &&
                moment(institution.billing.subscriptionStart).format(DATE_FORMAT)}
            </Descriptions.Item>
            <Descriptions.Item label='Subscription end'>
              {institution?.billing?.subscriptionEnd &&
                moment(institution.billing.subscriptionEnd).format(DATE_FORMAT)}
            </Descriptions.Item>
            <Descriptions.Item label='Institution'>
              {institution?.realInstitution ? 'Real' : 'Demo'}
            </Descriptions.Item>
            {institution?.type === Institution.School && (
              <Descriptions.Item label='PDF password'>{institution?.pdfPassword}</Descriptions.Item>
            )}
            {institution.partner && (
              <Descriptions.Item label='Partner'>
                <Link to={routes => routes.partner({ id: institution.partner?.id ?? '' })}>
                  {institution.partner.name}
                </Link>
              </Descriptions.Item>
            )}
            <Descriptions.Item label='Current Subscription status'>
              {institution?.billing?.subscriptionStatus}
            </Descriptions.Item>
            {institution?.billing?.customerUrl && (
              <Descriptions.Item label='Subscription info'>
                <a target='_blank' href={institution.billing.customerUrl} rel='noreferrer noopener'>
                  Chargebee
                </a>
              </Descriptions.Item>
            )}
            {institution?.partner?.id && (
              <Descriptions.Item label='Invoiced to'>
                {institution?.billing?.invoicedTo}
              </Descriptions.Item>
            )}
          </Descriptions>
        </Card>
        <Card title='Actions'>
          <Space style={{ flexWrap: 'wrap' }}>
            {isAllowedCreatePaymentUrl &&
              institution?.registered &&
              institution?.billing?.customerUrl && (
                <>
                  <PaymentLink motherId={institution?.id} />
                  <PaymentLink motherId={institution?.id} isOfflinePayment />
                </>
              )}
            {isAllowedApproveOrRejectSchool &&
              !institution?.isVerified &&
              institution.billing?.plan !== Plan.Unregistered && (
                <RegistrationButtons
                  institutionId={institution?.id ?? null}
                  type={institution?.type}
                />
              )}
            {isAllowedInstitutionLink &&
              (institution?.partner?.id ? (
                <UnlinkCompanyFromPartner
                  partnerId={institution?.partner?.id ?? ''}
                  motherId={institution?.id ?? ''}
                />
              ) : institution?.registered ? (
                <LinkCompanyToPartner
                  partnerId={institution?.partner?.id}
                  motherId={institution?.id ?? ''}
                />
              ) : null)}
            <ForceDeleteInstitutionButton
              institutionId={institution?.id ?? null}
              type={institution?.type}
              realInstitution={institution?.realInstitution}
              disabled={!isAllowedInstitutionEdit}
            />
          </Space>
        </Card>
        <ReportSourcesCard institutionId={institution?.id} />
        <MembersTable institutionId={institution?.id} />
      </Space>
    </ContentLayout>
  )
}

export default InstitutionDetail
