import { gql, useMutation } from '@apollo/client'
import { useAccessRights } from '@faceup/member'
import type { ModalProps } from '@faceup/ui'
import { notification } from '@faceup/ui-base'
import { sharedMessages } from '../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../TypedIntl'
import {
  type CreateMemberModal_institution,
  type CreateMemberModal_member,
  type CreateMemberMutation,
  type CreateMemberMutationVariables,
  PermissionType,
} from '../../__generated__/globalTypes'
import { useMotherId } from '../../providers'
import { AbstractMemberModal, AbstractMemberModalFragments } from './AbstractMemberModal'
import { findPermissionByPermissionType } from './AbstractMemberModal/abstractMemberModalHelpers'

const messages = defineMessages({
  title: 'Administration.settings.createNewUser.title',
})

export const CreateMemberModalFragments = {
  CreateMemberModal_member: gql`
    fragment CreateMemberModal_member on Member {
      id
      isAccountOwner(motherId: $motherId)

      companies(motherId: $motherId) {
        id
      }

      keys {
        id
        permissions(motherId: $motherId) {
          type
          enabled
          additionalData {
            categoryIds
          }
        }
      }

      ...AbstractMemberModal_member
    }

    ${AbstractMemberModalFragments.AbstractMemberModal_member}
  `,
  CreateMemberModal_institution: gql`
    fragment CreateMemberModal_institution on Company {
      id
      config {
        id
        reportCategories {
          id
        }
      }
      organizationalStructure {
        id
        organizationalUnitName
      }

      ...AbstractMemberModal_institution
    }
    ${AbstractMemberModalFragments.AbstractMemberModal_institution}
  `,
}

const mutations = {
  CreateMember: gql`
    mutation CreateMemberMutation($input: CreateMemberInput!, $motherId: CompanyGlobalId!) {
      createMember(input: $input) {
        createdMember {
          id
          name
          phone
          email

          # need up-to-date count for addMember upsell
          mother(motherId: $motherId) {
            id
            countOfMembers
          }
        }
      }
    }
  `,
}

type CreateMemberModalProps = {
  onCompleted: (data?: CreateMemberMutation) => void
  member: CreateMemberModal_member | null
  institution: CreateMemberModal_institution
} & Required<Pick<ModalProps, 'opened' | 'onClose'>>

export const CreateMemberModal = ({
  member,
  institution,
  onCompleted,
  opened,
  onClose,
}: CreateMemberModalProps) => {
  const { formatMessage } = useIntl()
  const { getMotherId } = useMotherId()
  const accessRights = useAccessRights()

  const [createMember] = useMutation<CreateMemberMutation, CreateMemberMutationVariables>(
    mutations.CreateMember,
    {
      onError: error => {
        console.error(error)
        notification.error({
          message: formatMessage(sharedMessages.apiError),
          description: error.message,
        })
      },
      onCompleted,
    }
  )

  const isKredenc = !member

  return (
    <AbstractMemberModal
      modalVariant='add'
      title={<FormattedMessage {...messages.title} />}
      member={member}
      institution={institution}
      opened={opened}
      onClose={onClose}
      defaultValues={{
        email: '',
        institutionIds: isKredenc
          ? institution.organizationalStructure.map(institution => institution.id)
          : (member.companies?.map(company => company.id) ?? []),
        permissions: Object.values(PermissionType).map(type => {
          const enabled = member?.isAccountOwner || accessRights.isVisibleForPermission[type]

          if (type === PermissionType.ReportAccess) {
            return {
              type,
              enabled,
              additionalData: isKredenc
                ? {
                    categoryIds: null,
                  }
                : findPermissionByPermissionType(member.keys?.permissions ?? [], type)
                    ?.additionalData,
            }
          }
          return {
            type,
            enabled,
          }
        }),
      }}
      onSubmit={async values => {
        const result = await createMember({
          variables: {
            motherId: getMotherId(),
            input: {
              motherId: getMotherId(),
              companyIds: values.institutionIds ?? [],
              email: values.email,
              permissions: values.permissions,
            },
          },
        })

        if (result.errors) {
          return false
        }

        onClose()
        return true
      }}
    />
  )
}
