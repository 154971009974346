import { deletePersonalKeys } from '@faceup/crypto'
import * as Sentry from '@sentry/react'
import { useContext } from 'react'
import { type ApplicationType, UserContext } from '../providers'

const AUTH_TOKEN_NAME = 'jwt'

const memberPrefix: Record<Extract<ApplicationType, 'akutan' | 'kredenc'>, string> = {
  akutan: 'Member',
  kredenc: 'FuAdmin',
}

export const useAuth = () => {
  const { application } = useContext(UserContext)

  const logout = () => {
    Sentry.setUser(null)

    localStorage.removeItem(AUTH_TOKEN_NAME)
    sessionStorage.removeItem(AUTH_TOKEN_NAME)
    deletePersonalKeys()
  }

  const setJwt = ({ jwt, persistent = false }: { jwt: string; persistent?: boolean }) => {
    Sentry.setUser({ id: getUserIdFromJWT(jwt) })

    if (persistent) {
      localStorage.setItem(AUTH_TOKEN_NAME, jwt)
    } else {
      sessionStorage.setItem(AUTH_TOKEN_NAME, jwt)
    }
  }

  const getJwt = () => {
    const localToken = localStorage.getItem(AUTH_TOKEN_NAME)
    if (localToken) {
      Sentry.setUser({ id: getUserIdFromJWT(localToken) })
      return localToken
    }

    const sessionToken = sessionStorage.getItem(AUTH_TOKEN_NAME)
    if (sessionToken) {
      Sentry.setUser({ id: getUserIdFromJWT(sessionToken) })
      return sessionToken
    }

    Sentry.setUser(null)
    return null
  }

  const isAuthenticated = () => Boolean(getJwt())

  const isRemembered = () => Boolean(localStorage.getItem(AUTH_TOKEN_NAME))

  const getUserIdFromJWT = (jwt: string) => {
    const payload = (jwt.split('.') ?? [])[1]
    if (!payload) {
      return ''
    }

    // id and userType is undefined for SSO users
    const { id, userType, username } = JSON.parse(atob(payload))

    if (!application) {
      return ''
    }

    if (application === 'follow-up') {
      throw new Error('Implement follow-up into auth')
    }

    return `${userType ?? memberPrefix[application]}}:${id ?? username}`
  }

  return {
    logout,
    setJwt,
    getJwt,
    isAuthenticated,
    isRemembered,
    getUserIdFromJWT,
  }
}
