import type { UntitledIconData } from '@faceup/icons'

export const ucUpsell: UntitledIconData = {
  prefix: 'custom',
  name: 'upsell',
  paths: [
    {
      shape:
        'M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1zM7.293 11.293l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L13 10.414V16a1 1 0 11-2 0v-5.586l-2.293 2.293a1 1 0 01-1.414-1.414z',
      fill: 'currentColor',
    },
  ],
}
