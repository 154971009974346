import { createPolymorphicComponent } from '@mantine/core'
import { type ReactNode, forwardRef } from 'react'
import type { ColorVariant } from '../hooks'
import { BaseComponent, type BaseComponentProps, type BoxShadow } from './BaseComponent'

export const elevations = [0, 1, 2, 3, 4, 5] as const
type Elevation = (typeof elevations)[number]
const radiuses = ['6px', '8px', '12px', '16px'] as const
type Radius = (typeof radiuses)[number]

type PaperProps = {
  children?: ReactNode
  elevation?: Elevation
  outline?: ColorVariant | 'unset'
  background?: ColorVariant
  radius?: Radius
} & BaseComponentProps

const elevationShadow: Record<Elevation, BoxShadow> = {
  0: 'none',
  1: '0px 1px 2px rgba(6, 45, 70, 0.2), 0px 1px 3px 1px rgba(6, 45, 70, 0.12)',
  2: '0px 1px 2px rgba(6, 45, 70, 0.2), 0px 3px 6px 2px rgba(6, 45, 70, 0.1)',
  3: '0px 6px 10px 4px rgba(6, 45, 70, 0.08), 0px 2px 3px rgba(6, 45, 70, 0.2)',
  4: '0px 8px 12px 6px rgba(6, 45, 70, 0.08), 0px 4px 4px rgba(6, 45, 70, 0.16)',
  5: '0px 2px 20px 0px rgba(22, 25, 84, 0.10)',
}

const _Paper = forwardRef<HTMLDivElement, PaperProps>((_props, ref) => {
  const {
    children,
    elevation = 0,
    outline = 'dark',
    background,
    radius = '16px',
    ...props
  } = _props
  return (
    <BaseComponent
      ref={ref}
      {...props}
      sx={({ getColorFromTheme, mergeSx }) =>
        mergeSx(
          {
            boxShadow: elevationShadow[elevation],
            border:
              outline !== 'unset' ? `1px solid ${getColorFromTheme(`${outline}.20`)}` : undefined,
            backgroundColor: background
              ? getColorFromTheme(`${background}.10`)
              : getColorFromTheme('white'),
            borderRadius: radius,
            // TODO: set from parent?
            overflow: 'hidden',
          },
          props.sx ?? {}
        )
      }
    >
      {children}
    </BaseComponent>
  )
})

_Paper.displayName = 'Paper'

export const Paper = createPolymorphicComponent<'div', PaperProps>(_Paper)
