import { App } from 'antd'
import AntSizeContext from 'antd/es/config-provider/SizeContext'
export type { Locale } from 'antd/es/locale'
export { ConfigContext } from 'antd/es/config-provider'
export {
  App,
  List,
  Select,
  type SelectProps,
  type RefSelectProps,
  Space,
  Card,
  type CardProps,
  Tooltip,
  Input,
  type InputProps,
  Upload,
  Form,
  Row,
  Col,
  Badge,
  type BadgeProps,
  Skeleton,
  Layout,
  notification,
  ConfigProvider,
  Checkbox,
  type CheckboxProps,
  Empty,
  Radio,
  Pagination,
  Slider,
  Spin,
  Result,
  TreeSelect,
  Divider,
  Tag,
  Segmented,
  Popconfirm,
  Popover,
  type PopoverProps,
  Progress,
  Rate,
  Collapse,
  type InputRef,
  type ProgressProps,
  type SpaceProps,
  Descriptions,
  Flex,
  Menu,
  type MenuProps,
  type FormItemProps,
  QRCode,
  ColorPicker,
  type QRCodeProps,
  InputNumber,
  type InputNumberProps,
} from 'antd'

export type { TextAreaProps } from 'antd/es/input/TextArea'
export type { SizeType } from 'antd/es/config-provider/SizeContext'
export type { CheckboxChangeEvent } from 'antd/es/checkbox'
export type { BaseOptionType } from 'antd/es/select'
export type { DefaultOptionType } from 'antd/es/cascader'
export type { ItemType } from 'antd/es/menu/interface'
export type { SortOrder, SorterResult } from 'antd/es/table/interface'

export { Alert } from './Alert'
export { DatePicker, type DatePickerProps } from './DatePicker'
export { Dropdown, type DropdownProps } from './Dropdown'
export { TimePicker, type TimePickerProps } from './TimePicker'
export { Avatar, type AvatarProps } from './Avatar'
export { Modal, type ModalProps } from './Modal'
export { Button, type ButtonProps } from './Button'
export { UiBaseProvider, useUiBaseConfig } from './UiBaseProvider'
export { useModal } from './useModal'
export { Tabs, type TabsProps } from './Tabs'
export { Table, type TableProps } from './Table'
export { Typography } from './Typography'

export const SizeContext = AntSizeContext
export { constants } from './constants'
export { locales } from './locales'
export * from './Icons'

// We need to show message via hook only, not static function
export const useMessage = () => {
  const app = App.useApp()
  return app.message
}
