import { ChargebeeSubscriptionStatus, Feature, Institution, Plan } from '@faceup/utils'
import { useContext } from 'react'
import { PermissionType } from '../__generated__/globalTypes'
import { UserContext, useUser } from '../providers'
import { useGlobalInfo } from './useGlobalInfo'
import { usePartner } from './usePartner'

export type UseAccessRightsReturn = {
  isAllowedAdvancedCustomization: boolean
  isAllowedSettingsAccess: boolean
  isAllowedSSO: boolean
  isAllowedWebhooks: boolean
  isAllowedApi: boolean
  isVisibleWhitelabel: boolean
  isAllowedWhitelabel: boolean
  isVisibleEnableAccessKey: boolean
  isAllowedEnableAccessKey: boolean
  isAllowedE2EE: boolean
  areReportsNeedingAttentionVisible: boolean
  isBioVisible: boolean
  isAllowedMultipleReportSources: boolean
  isVisiblePdfPasswordAlert: boolean
  isAllowedRedaction: boolean
  isAllowedStatistics: boolean
  isAllowedSurveys: boolean
  isAllowedCustomizationEdit: boolean
  isVisibleForPermission: Record<PermissionType, boolean>
  isPartnerAdministration: boolean
  mothersWithPermission: Record<PermissionType, string[]>
  isAllowedIntegration: boolean
  isAllowedReportsBadge: boolean
  isAllowedCreateHotline: boolean
  isAllowedCreateLiveHotline: boolean
  areReportAttachmentsVisible: boolean
  isAllowedAiAssistant: boolean
  areVisibleDataManagementEmployees: boolean
  areVisibleReportEmployees: boolean
}

type FnUseAccessRights = () => UseAccessRightsReturn

export const useAccessRights: FnUseAccessRights = () => {
  const { plan, institution, entitlements, subscriptionStatus, integratedApps } = useGlobalInfo()
  const { hasPermissions } = usePermissions()
  const { isPartnerAdministration } = usePartner()
  const { viewer } = useContext(UserContext)

  const isActive = [
    ChargebeeSubscriptionStatus.Active,
    ChargebeeSubscriptionStatus.NonRenewing,
  ].includes(subscriptionStatus ?? ChargebeeSubscriptionStatus.Cancelled)

  /*
   * isAllowed - has access to item (e.g. to persist data) - to show buy higher pricing plan info
   * isVisible - is item visible or fully hidden in page
   */
  const isSchool = institution === Institution.School
  const isFreeSchool = isSchool && plan !== null && plan === Plan.Free
  const isPlanStandardOrPremium = Boolean(plan && [Plan.Standard, Plan.Premium].includes(plan))
  const isSchoolOrNotActiveInstitution = isSchool || !isActive

  const isVisibleForPermission = Object.values(PermissionType).reduce(
    (acc, permission) => ({
      ...acc,
      [permission]: hasPermissions(permission),
    }),
    {} as Record<PermissionType, boolean>
  )

  const isEntitlementEnabled = (feature: Feature) =>
    entitlements.find(entitlement => entitlement.id === feature)?.isEnabled ?? false

  const organizationalUnitsWithAccess = new Set(viewer?.partnerCompanyIds)
  const mothersWithPermission = Object.values(PermissionType).reduce(
    (acc, permission) => ({
      ...acc,
      [permission]:
        viewer?.keys.partnerPermissions
          ?.filter(
            partnerPermission => partnerPermission.type === permission && partnerPermission.enabled
          )
          .map(permission => permission.motherId)
          .filter(id => id && organizationalUnitsWithAccess.has(id)) ?? [],
    }),
    {} as Record<PermissionType, string[]>
  )

  return {
    // Instead of changing all the places where this property is used I assume that partner is always allowed to
    // edit advanced customization for partner config (motherId does not exist). For institution config the same
    // rules as for other users still apply.
    isAllowedAdvancedCustomization: isPlanStandardOrPremium,
    isAllowedSettingsAccess: isVisibleForPermission.SettingsAccess,
    isAllowedSSO: isEntitlementEnabled(Feature.Sso),
    isAllowedWebhooks: isEntitlementEnabled(Feature.Webhooks),
    isAllowedApi: isEntitlementEnabled(Feature.Api),
    isVisibleWhitelabel: !isSchool && isVisibleForPermission.SettingsAccess,
    isAllowedWhitelabel: isEntitlementEnabled(Feature.Whitelabel),
    isVisibleEnableAccessKey: isSchool,
    isAllowedEnableAccessKey: !isFreeSchool,
    isAllowedE2EE: !isFreeSchool,
    areReportsNeedingAttentionVisible: !isFreeSchool && isVisibleForPermission.ReportAccess,
    isBioVisible: !isFreeSchool,
    isAllowedMultipleReportSources:
      institution === Institution.Company && isPlanStandardOrPremium && isActive,
    isVisiblePdfPasswordAlert: isFreeSchool,
    isAllowedRedaction: isPlanStandardOrPremium,
    isAllowedStatistics: isVisibleForPermission.Analytics && !isFreeSchool,
    isAllowedSurveys: isVisibleForPermission.Surveys && !isFreeSchool,
    isAllowedCustomizationEdit:
      isPlanStandardOrPremium && isVisibleForPermission.ManageReportingChannels,
    isVisibleForPermission,
    isPartnerAdministration,
    mothersWithPermission,
    isAllowedIntegration:
      isEntitlementEnabled(Feature.Integrations) && isVisibleForPermission.SettingsAccess,
    isAllowedReportsBadge: !isFreeSchool,
    isAllowedCreateHotline: !isSchoolOrNotActiveInstitution,
    isAllowedCreateLiveHotline: !isSchoolOrNotActiveInstitution,
    areReportAttachmentsVisible: plan !== Plan.Basic,
    isAllowedAiAssistant: isPlanStandardOrPremium,
    areVisibleDataManagementEmployees: Boolean(
      integratedApps?.isUkgReadyEnabled ||
        integratedApps?.isUkgProEnabled ||
        integratedApps?.isBambooHrEnabled
    ),
    areVisibleReportEmployees: Boolean(
      integratedApps?.isUkgReadyEnabled ||
        integratedApps?.isUkgProEnabled ||
        integratedApps?.isBambooHrEnabled
    ),
  }
}

const usePermissions = () => {
  const { permissions } = useGlobalInfo()
  const user = useUser()

  const hasPermissions = (...requiredPermissions: PermissionType[]) => {
    if (user.application === 'kredenc') {
      return true
    }

    const enabledPermissionTypes = permissions
      .filter(permission => permission?.enabled)
      .map(permission => permission?.type)

    return requiredPermissions.every(requiredPermission =>
      enabledPermissionTypes.includes(requiredPermission)
    )
  }

  return {
    hasPermissions,
  }
}
