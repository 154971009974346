import { UntitledIcon, type UntitledIconData } from '@faceup/icons'
import { ulArrowLeft } from '@faceup/icons/ulArrowLeft'
import { Link, type RouteCallback } from '@faceup/router'
import { Col, Flex, Row, Space, Tooltip, Typography } from '@faceup/ui-base'
import { UnstyledButton } from '@mantine/core'
import { type ReactNode, useEffect, useRef, useState } from 'react'
import { HintIcon } from './HintIcon'

type ContentHeaderProps = {
  title: ReactNode
  titleHint?: string
  description?: ReactNode
  centerContent?: ReactNode
  secondaryContent?: ReactNode
  arrowGoBackTo?: RouteCallback
  action?: {
    icon: UntitledIconData
    onAction: () => void
  }
}

export const ContentHeader = ({
  title,
  titleHint,
  description,
  centerContent,
  secondaryContent,
  arrowGoBackTo,
  action,
}: ContentHeaderProps) => {
  const titleRef = useRef<HTMLDivElement | null>(null)
  const [isTruncated, setIsTruncated] = useState(false)

  useEffect(() => {
    const checkEllipsis = () => {
      if (titleRef.current) {
        const { scrollWidth, clientWidth } = titleRef.current
        setIsTruncated(scrollWidth > clientWidth)
      }
    }
    checkEllipsis()
    window.addEventListener('resize', checkEllipsis)
    return () => window.removeEventListener('resize', checkEllipsis)
  }, [])

  return (
    <Space direction='vertical' size={20} style={{ width: '100%' }}>
      <Flex justify='space-between' wrap='nowrap' align='center'>
        <Flex wrap='nowrap' gap='middle' style={{ maxWidth: 'calc(100% - 664px)' }}>
          {arrowGoBackTo && (
            <UnstyledButton
              to={arrowGoBackTo}
              component={Link}
              sx={{
                fontSize: 24,
              }}
            >
              <UntitledIcon icon={ulArrowLeft} />
            </UnstyledButton>
          )}
          <Tooltip title={isTruncated ? title : ''}>
            <Typography.Title ellipsis level={3} ref={titleRef}>
              {title}
            </Typography.Title>
          </Tooltip>
          {action && (
            <UnstyledButton onClick={action.onAction}>
              <UntitledIcon icon={action.icon} size={16} />
            </UnstyledButton>
          )}
          {titleHint && <HintIcon title={titleHint} variant='heading' />}
        </Flex>
        {centerContent && <div>{centerContent}</div>}
        {secondaryContent && (
          <div
            style={{
              flexGrow: centerContent ? undefined : 1,
            }}
          >
            {secondaryContent}
          </div>
        )}
      </Flex>
      {description && (
        <Row>
          <Col span={24}>{description}</Col>
        </Row>
      )}
    </Space>
  )
}
