import { gql } from '@apollo/client'
import { areArraysEqual } from '@faceup/utils'
import { type Dispatch, type SetStateAction, useEffect, useMemo, useState } from 'react'
import type { useManageReportCustomInputsFragments_answer } from '../__generated__/globalTypes'
import {
  usePrepareFormItemAnswersForMutation,
  usePrepareFormItemAnswersForMutationFragments,
} from './usePrepareFormItemAnswersForMutation'

export const useManageReportCustomInputsFragments = {
  useManageReportCustomInputsFragments_answer: gql`
    fragment useManageReportCustomInputsFragments_answer on CaseAnswer {
      id

      ...usePrepareFormItemAnswersForMutationFragments_answer
    }
    ${
      usePrepareFormItemAnswersForMutationFragments.usePrepareFormItemAnswersForMutationFragments_answer
    }
  `,
}

export type Answers = Record<string, Answer>

type Answer = {
  values: string[] | null
  error?: string
}

export const useManageReportCustomInputs = (
  categoryId: string | null,
  reportSourceId: string | null,
  answers?: useManageReportCustomInputsFragments_answer[]
): {
  customFormItems: Answers
  setCustomFormItems: Dispatch<SetStateAction<Answers>>
  resetCustomFormItems: () => void
  validateCustomFormItems: () => Promise<boolean>
  getValuesForMutation: () => Promise<{ formItemId: string; values: string[] | null }[]>
  areCustomFormItemsInDefaultState: () => boolean
} => {
  const { prepareForm } = usePrepareFormItemAnswersForMutation()
  const defaultFormItems = useMemo(() => parseGqlAnswerToAnswersType(answers ?? []), [answers])

  const [customFormItems, setCustomFormItems] = useState<Answers>({})

  useEffect(() => {
    setCustomFormItems(defaultFormItems)
  }, [defaultFormItems])

  const { validateAnswers, getValuesForMutation: prepareValuesForMutation } = prepareForm(
    // this shouldn't ever happen
    reportSourceId ?? '',
    categoryId ?? ''
  )

  const resetCustomFormItems = () => {
    setCustomFormItems({})
  }

  const validateCustomFormItems = async () => validateAnswers(customFormItems, setCustomFormItems)

  const isValueInDefaultState = (formItemId: string, values: string[] | null) => {
    const defaultFormItem = defaultFormItems[formItemId]
    return defaultFormItem?.values && values && areArraysEqual(defaultFormItem?.values, values)
  }
  const getValuesForMutation = async () =>
    (await prepareValuesForMutation(customFormItems)).filter(
      ({ values, formItemId }) => !isValueInDefaultState(formItemId, values)
    )

  const areCustomFormItemsInDefaultState = () =>
    isObjectContained(defaultFormItems, customFormItems) &&
    isObjectContained(customFormItems, defaultFormItems)

  return {
    customFormItems,
    setCustomFormItems,
    resetCustomFormItems,
    validateCustomFormItems,
    getValuesForMutation,
    areCustomFormItemsInDefaultState,
  }
}

export const parseGqlAnswerToAnswersType = (
  answers: readonly useManageReportCustomInputsFragments_answer[]
): Answers =>
  answers.reduce<Answers>(
    (result, answer) => ({
      ...result,
      [answer.formItem.formItemId]: { values: answer.values ?? '' },
    }),
    {}
  ) ?? {}

const isObjectContained = (obj1: Answers, obj2: Answers): boolean =>
  Object.keys(obj1).every(key => areArraysEqual(obj1[key]?.values ?? [], obj2[key]?.values ?? []))
