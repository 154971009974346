import { Typography } from '@faceup/ui-base'
import { MantineProvider, useMantineTheme } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import merge from 'deepmerge'
import { type ReactNode, useMemo } from 'react'
import { MoreInfo } from '../IconWithLogic'
import styles from './assets/FormItem.module.css'

export type FormItemProps = {
  children: ReactNode
  label?: ReactNode
  description?: ReactNode
  hint?: ReactNode
  secondary?: ReactNode
  withAsterisk?: boolean
  errorMessage?: ReactNode
  beforeInput?: ReactNode
  afterInput?: ReactNode
  withoutWrapper?: boolean
  variant?: 'gray' | 'black'
  'data-cy'?: string
}

export const FormItem = ({
  children,
  label,
  hint,
  description,
  secondary,
  withAsterisk,
  errorMessage,
  beforeInput,
  afterInput,
  variant = 'gray',
  withoutWrapper = false,
  'data-cy': dataCy,
}: FormItemProps) => {
  const theme = useMantineTheme()
  const { ref: secondarySizeRef, width: secondaryWidth } = useElementSize()
  const mergedTheme = useMemo(
    () =>
      merge(theme, {
        components: {
          Input: {
            styles: {
              input: {
                borderColor: errorMessage ? theme.colors.red[7] : undefined,
                color: errorMessage ? theme.colors.red[7] : undefined,
                '&:focus': {
                  borderColor: errorMessage ? theme.colors.red[7] : undefined,
                },
              },
            },
          },
          InputWrapper: {
            styles: {
              root: {
                paddingBlockEnd: '0',
              },
            },
          },
        },
      }),
    [theme, errorMessage]
  )

  if (withoutWrapper) {
    return (
      <MantineProvider inherit theme={mergedTheme}>
        {children}
      </MantineProvider>
    )
  }

  const isSomethingInLabelRow = label || hint || secondary

  return (
    <div className={styles.formItem} data-cy={dataCy}>
      {secondary && (
        <div className={styles.formItemSecondary} ref={secondarySizeRef}>
          {secondary}
        </div>
      )}
      {/* biome-ignore lint/a11y/noLabelWithoutControl: */}
      <label>
        <div className={styles.formItemInnerLabel} style={{ gap: isSomethingInLabelRow ? 4 : 0 }}>
          <div style={{ width: `calc(100% - ${secondaryWidth}px)` }}>
            {variant === 'gray' ? (
              <Typography.Text strong className={styles.formItemText}>
                <span style={{ color: errorMessage ? '#F54070' : 'unset' }}>{label}</span>{' '}
                {withAsterisk && (
                  <Typography.Text type='danger' style={{ fontSize: '12px' }}>
                    *
                  </Typography.Text>
                )}{' '}
                {hint && <MoreInfo title={hint} />}{' '}
              </Typography.Text>
            ) : (
              <Typography.Title level={4}>
                <span style={{ color: errorMessage ? '#F54070' : 'unset' }}>{label}</span>{' '}
                {withAsterisk && <span style={{ color: '#F54070' }}>*</span>}{' '}
                {hint && <MoreInfo title={hint} />}{' '}
              </Typography.Title>
            )}
          </div>
          {description && <Typography.Text type='secondary'>{description}</Typography.Text>}
          <div className={styles.formItemWrapper}>
            {beforeInput && <div>{beforeInput}</div>}
            <div className={styles.formItemInnerWrapper}>
              <MantineProvider inherit theme={mergedTheme}>
                {children}
              </MantineProvider>
            </div>
            {afterInput && <div>{afterInput}</div>}
          </div>
        </div>
      </label>
      <Typography.Text type='danger'>{errorMessage}</Typography.Text>
    </div>
  )
}
