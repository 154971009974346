import { Select } from '@faceup/ui-base'
import { SupportedRegion, convertRegionToRegionName } from '@faceup/utils'
import useRegion from '../utils/useRegion'

const RegionSelect = () => {
  const { region, setRegion } = useRegion()

  return (
    <Select
      value={region}
      onSelect={newRegion => setRegion(newRegion)}
      style={{ maxWidth: '200px' }}
      options={Object.values(SupportedRegion).map(region => ({
        label: convertRegionToRegionName(region),
        value: region,
      }))}
    />
  )
}

export default RegionSelect
