import { Box, createPolymorphicComponent } from '@mantine/core'
import deepmerge from 'deepmerge'
import { type AllHTMLAttributes, type ReactNode, forwardRef } from 'react'
import { fontWeightBold } from '../constants'
import { type UseThemeColors, useThemeColors } from '../hooks'

type FnMergeSx = (propsSx: Sx | undefined, sx: Sx) => CSSObject
type Theme = {
  mergeSx: FnMergeSx
} & UseThemeColors

export type BaseComponentProps = {
  children?: ReactNode
  sx?: Sx
}

const _BaseComponent = forwardRef<HTMLDivElement, BaseComponentProps>(({ sx, ...props }, ref) => {
  const { getCssObject } = useStyling()
  return <Box ref={ref} {...props} sx={getCssObject(sx)} />
})

_BaseComponent.displayName = 'BaseComponent'

export const BaseComponent = createPolymorphicComponent<'div', BaseComponentProps>(_BaseComponent)

// Styling
// Maybe one day it can be separated from this file
// We want to have strongly typed styling
// If we have to use some unusual value,
// it should be consulted with design team if is it really necessary

export const fontFamilyInter = 'Inter, sans-serif'
const fontFamilies = [fontFamilyInter] as const
export type FontFamily = (typeof fontFamilies)[number]

const fontSizes = [
  '12px',
  '14px',
  '16px',
  '20px',
  '24px',
  '28px',
  '32px',
  '40px',
  '48px',
  '56px',
] as const
export type FontSize = (typeof fontSizes)[number]

const fontWeights = [400, fontWeightBold] as const
export type FontWeight = (typeof fontWeights)[number]

const boxShadows = [
  'none',
  // Cards
  '0px 1px 2px rgba(6, 45, 70, 0.2), 0px 1px 3px 1px rgba(6, 45, 70, 0.12)',
  '0px 1px 2px rgba(6, 45, 70, 0.2), 0px 3px 6px 2px rgba(6, 45, 70, 0.1)',
  '0px 6px 10px 4px rgba(6, 45, 70, 0.08), 0px 2px 3px rgba(6, 45, 70, 0.2)',
  '0px 8px 12px 6px rgba(6, 45, 70, 0.08), 0px 4px 4px rgba(6, 45, 70, 0.16)',
  // InputSkeleton
  '0px 0px 0px 2px #0E9AF733',
]
export type BoxShadow = (typeof boxShadows)[number]

export type Border = string

export type Color = string

const outlines = ['none'] as const
export type Outline = (typeof outlines)[number]

const boxSizings = ['border-box'] as const
export type BoxSizing = (typeof boxSizings)[number]

const overflows = ['hidden'] as const
export type Overflow = (typeof overflows)[number]

const heights = ['40px', '100%', '8px', '6px', '154px', '330px'] as const
export type Height = (typeof heights)[number]

const widths = ['100%', '297mm', '8px', '6px'] as const
export type Width = (typeof widths)[number]

const borderRadiuses = ['6px', '8px', '12px', '16px', '50%'] as const
export type BorderRadius = (typeof borderRadiuses)[number]

const positions = ['relative'] as const
export type Position = (typeof positions)[number]

const display = ['none', 'flex', '-webkit-box'] as const
export type Display = (typeof display)[number]

const alignItems = ['center', 'stretch'] as const
export type AlignItems = (typeof alignItems)[number]

const justifyContents = ['space-between', 'flex-end'] as const
export type JustifyContent = (typeof justifyContents)[number]

export type Transition = string

export type Padding = string | 0

export type Margin = string | 0

const gaps = ['8px'] as const
export type Gap = (typeof gaps)[number]

const cursors = ['default', 'pointer'] as const
export type Cursor = (typeof cursors)[number]

const userSelects = ['none'] as const
export type UserSelect = (typeof userSelects)[number]

export type Flex = string

const textAligns = ['left', 'center'] as const
export type TextAlign = (typeof textAligns)[number]

export type Background = string

const minHeight = ['50px'] as const
export type MinHeight = (typeof minHeight)[number]

const textOverflow = ['ellipsis'] as const
export type TextOverflow = (typeof textOverflow)[number]

const pageBreakAfter = ['always'] as const
export type PageBreakAfter = (typeof pageBreakAfter)[number]

const whiteSpace = ['nowrap', 'initial'] as const
export type WhiteSpace = (typeof whiteSpace)[number]

const wordBreak = ['break-word'] as const
export type WordBreak = (typeof wordBreak)[number]

export type CssAttributes = {
  fontFamily?: FontFamily
  fontSize?: FontSize
  fontWeight?: FontWeight
  boxShadow?: BoxShadow
  border?: Border
  color?: Color
  borderColor?: Color
  outline?: Outline
  boxSizing?: BoxSizing
  overflow?: Overflow
  height?: Height
  width?: Width
  borderRadius?: BorderRadius
  position?: Position
  display?: Display
  alignItems?: AlignItems
  justifyContent?: JustifyContent
  transition?: Transition
  padding?: Padding
  paddingInline?: Padding
  paddingBlock?: Padding
  paddingInlineStart?: Padding
  paddingInlineEnd?: Padding
  paddingBlockStart?: Padding
  paddingBlockEnd?: Padding
  margin?: Margin
  marginInline?: Margin
  marginBlock?: Margin
  marginInlineStart?: Margin
  marginInlineEnd?: Margin
  marginBlockStart?: Margin
  marginBlockEnd?: Margin
  gap?: Gap
  cursor?: Cursor
  userSelect?: UserSelect
  backgroundColor?: Color
  flex?: Flex
  textAlign?: TextAlign
  background?: Background
  minHeight?: MinHeight
  textOverflow?: TextOverflow
  pageBreakAfter?: PageBreakAfter
  whiteSpace?: WhiteSpace
  wordBreak?: WordBreak
  WebkitLineClamp?: 2
  WebkitBoxOrient?: 'vertical'
  textDecoration?: 'underline'
}

type RealProps = keyof AllHTMLAttributes<unknown>
type UnusedRealProps = Exclude<RealProps, keyof CssAttributes>

export type SxSimplest =
  | (CssAttributes & {
      [key in UnusedRealProps]?: never
    })
  | undefined

export type Sx = CSSObject | ((theme: Theme) => CSSObject)

// CSSTypes simplified from mantine

export type CSSObject = CSSPseudos | CSSPropertiesWithMultiValues | CSSOthersObject

export type CSSProperties = CssAttributes & {
  [key in UnusedRealProps]?: never
}
export type CSSPropertiesWithMultiValues = {
  [K in keyof CSSProperties]: CSSProperties[K] /* | Array<Extract<CSSProperties[K], string>>*/
}

type CSSPseudos = { ':-moz-any-link'?: CSSObject }

type CSSOthersObject = { [Key: string | number]: CSSObject }

const useStyling = () => {
  const theme = useThemeColors()

  const mergeSx: FnMergeSx = (propsSx, sx): CSSObject => {
    if (propsSx === undefined) {
      return typeof sx === 'function' ? sx(themeObj) : sx
    }
    const cssObjProps: CSSObject = typeof propsSx === 'function' ? propsSx(themeObj) : propsSx
    const cssObj: CSSObject = typeof sx === 'function' ? sx(themeObj) : sx

    return deepmerge<CSSObject>(cssObjProps, cssObj)
  }

  const themeObj: Theme = {
    ...theme,
    mergeSx,
  }

  const getCssObject: (sx: Sx | undefined) => CSSObject = sx => {
    if (sx === undefined) {
      return {}
    }
    if (Array.isArray(sx)) {
      return sx.map(getCssObject).reduce((acc, curr) => ({ ...acc, ...curr }), {})
    }
    if (typeof sx === 'function') {
      return getCssObject(sx(themeObj))
    }
    return sx
  }

  return { getCssObject }
}
