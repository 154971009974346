import { getSodium } from '../utils/general'

export class Random {
  public static async generateBytes(length: number) {
    const sodium = await getSodium()
    return sodium.randombytes_buf(length)
  }

  public static async generateBytesDeterministic(length: number, seed: Uint8Array) {
    const sodium = await getSodium()
    return sodium.randombytes_buf_deterministic(length, seed)
  }

  public static async generateInt(startIncluded: number, endExcluded: number) {
    const sodium = await getSodium()
    if (startIncluded >= endExcluded) {
      throw new Error('startIncluded must be less than endExcluded')
    }

    if (endExcluded > 0xffffffff) {
      throw new Error('endExcluded must be less than 0xffffffff')
    }

    return sodium.randombytes_uniform(endExcluded - startIncluded) + startIncluded
  }
}
