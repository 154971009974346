import { gql, useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { Card, ContentLayout, Paper } from '@faceup/ui'
import { Col, DatePicker, Row, Select, Space, Typography, notification } from '@faceup/ui-base'
import { Country, DEFAULT_COUNTRY, convertCountryToCountryName } from '@faceup/utils'
import moment from 'moment-timezone'
import { useState } from 'react'
import {
  type DashboardQuery,
  type DashboardQueryVariables,
  Institution,
} from '../__generated__/globalTypes'

const { Title } = Typography
const { RangePicker } = DatePicker

const query = {
  DashboardQuery: gql`
    query DashboardQuery(
      $dateFrom: DateTime!
      $dateTo: DateTime!
      $country: Country
      $institutionType: Institution
    ) {
      companyHomepageDashboardStats(
        dateFrom: $dateFrom
        dateTo: $dateTo
        country: $country
        institutionType: $institutionType
      ) {
        id
        reportsCount
        registrationsCount
        pendingRegistrationsCount
      }
    }
  `,
}

const Dashboard = () => {
  const [country, setCountry] = useState<Country>()
  const [institution, setInstitution] = useState<Institution>()
  const [dateFrom, setDateFrom] = useState(moment('2017-01-01'))
  const [dateTo, setDateTo] = useState(moment().endOf('year'))

  const { data } = useQuery<DashboardQuery, DashboardQueryVariables>(query.DashboardQuery, {
    variables: {
      country: country ?? undefined,
      dateFrom: dateFrom.toString(),
      dateTo: dateTo.toString(),
      institutionType: institution,
    },
    fetchPolicy: 'cache-and-network',
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL ERROR',
        description: error.message,
      })
    },
  })

  const stats = data?.companyHomepageDashboardStats

  return (
    <ContentLayout header={<ContentLayout.Header title='Hi! 👋 Welcome back' />}>
      <Space direction='vertical' size='large' style={{ width: '100%' }}>
        <Paper outline='dark'>
          <Row gutter={16} style={{ padding: '24px' }}>
            <Col xs={24} sm={8} md={6}>
              <InputLabel>Country</InputLabel>
              <Select
                style={{ width: '100%' }}
                allowClear
                placeholder='Country'
                onChange={setCountry}
                showSearch
                options={Object.values(Country).map(country => ({
                  label: convertCountryToCountryName(country ?? DEFAULT_COUNTRY, 'en'),
                  value: country ?? '',
                }))}
              />
            </Col>
            <Col xs={24} sm={8} md={6}>
              <InputLabel>Institution</InputLabel>
              <Select
                style={{ width: '100%' }}
                allowClear
                placeholder='Institution'
                onChange={setInstitution}
                options={Object.values(Institution).map(institution => ({
                  label: institution ?? '',
                  value: institution ?? '',
                }))}
              />
            </Col>
            <Col xs={24} sm={8} md={6}>
              <InputLabel>Date</InputLabel>
              <br />
              <RangePicker
                style={{ width: '100%' }}
                onChange={dates => {
                  setDateFrom(dates?.[0] ?? moment())
                  setDateTo(dates?.[1] ?? moment())
                }}
              />
            </Col>
          </Row>
        </Paper>

        <Row gutter={[24, 24]}>
          <Col xs={24} sm={8}>
            <Card title='Registrations'>
              <Title>{stats?.registrationsCount ?? '-'}</Title>
            </Card>
          </Col>
          <Col xs={24} sm={8}>
            <Card title='Reports'>
              <Title>{stats?.reportsCount ?? '-'}</Title>
            </Card>
          </Col>
          <Col xs={24} sm={8}>
            <Card title='Pending registrations'>
              <Title>{stats?.pendingRegistrationsCount ?? '-'}</Title>
            </Card>
          </Col>
        </Row>
      </Space>
    </ContentLayout>
  )
}

const InputLabel = styled.span`
  display: inline-block;
  margin: 10px 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #232323;
`

export default Dashboard
